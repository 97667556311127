<template>
    <div
        class="columns"
        style="height: 100%;"
    >
        <div
            id="left-image"
            class="column is-two-thirds is-hidden-mobile"
        />
        <div
            id="login-container"
            class="column has-text-light is-paddingless"
        >
            <div class="columns is-centered is-mobile">
                <div class="column is-three-quarters">
                    <div
                        id="login-form"
                        class="is-flex"
                    >
                        <a
                            class="logo"
                            href="#"
                        >
                            <img
                                :src="logoWhiteLink"
                                :alt="configStore.config.appName"
                            >
                        </a>
                        <div>
                            <p class="is-size-4 has-text-white has-text-centered">
                                {{ $t('login.intro') }}
                            </p>
                            <p class="is-size-5 has-text-centered" />
                        </div>
                        <form
                            method="POST"
                            action="/login"
                            :aria-label="$t('login.login')"
                        >
                            <input
                                type="hidden"
                                name="_token"
                                :value="csrf"
                            >
                            <b-field
                                :label="$t('login.email')"
                                :type="emailError ? ' is-danger' : ''"
                                :message="emailError"
                                custom-class="has-text-white"
                            >
                                <b-input
                                    :placeholder="$t('login.email')"
                                    type="email"
                                    name="email"
                                    icon-pack="fas"
                                    icon="user"
                                />
                            </b-field>
                            <b-field
                                custom-class="has-text-white"
                                :label="$t('login.password')"
                                :type="passwordError ? ' is-danger' : ''"
                                :message="passwordError"
                            >
                                <b-input
                                    type="password"
                                    name="password"
                                    icon-pack="fas"
                                    icon="lock"
                                    password-reveal
                                />
                            </b-field>
                            <div
                                id="reset-password"
                                class=""
                            >
                                <a
                                    class="btn btn-link has-text-white"
                                    href="/password/reset"
                                >
                                    {{ $t('login.passwordReset') }}
                                </a>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button
                                        type="submit"
                                        class="button is-primary is-fullwidth"
                                    >
                                        {{ $t('login.loginSubmit') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores, } from 'pinia'

import useConfigStore from '@/state/configStore.js'

export default {
    props: {
        csrf: {
            type: String,
            required: true,
        },
        emailError: {
            type: String,
            default: null,
        },
        passwordError: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapStores(useConfigStore),
        logoWhiteLink () {
            return this.configStore.config.logoUrls.white
        },
    },
}
</script>
